import React from "react";
import {
    Button,
    ButtonGroup,
    Card,
    CardSubtitle,
    CardTitle,
    Col,
    Row,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import sdk from 'api-sdk';
import Bullet from './bullet';
import SearchBar from '../../components/inputs/searchBar';
import vectors from '../../assets/data/attackVectors.json';
import PermissionPopup from "../../components/permissionPopup/permissionPopup";
import EDRs from '../../assets/data/edrs.json';
import Switch from '@mui/material/Switch';
import "./bulletMenu.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrosoft, faLinux, faApple, faAws, faAndroid } from '@fortawesome/free-brands-svg-icons';
import Icon from '@mdi/react';
import { mdiMicrosoftAzure, mdiAppleIos, mdiGoogleCloud } from '@mdi/js';
import PayloadModal from './bullet/payloadModal';
import DefRuleModal from "./bullet/defRuleModal";

const Platforms = [
    { name: 'Windows', platform: <FontAwesomeIcon icon={faMicrosoft} /> },
    { name: 'MacOs', platform: <FontAwesomeIcon icon={faApple} /> },
    { name: 'iOS', platform: <Icon path={mdiAppleIos} size={2} /> },
    { name: 'AWS', platform: <FontAwesomeIcon icon={faAws} /> },
    { name: 'Android', platform: <FontAwesomeIcon icon={faAndroid} /> },
    { name: 'Azure', platform: <Icon path={mdiMicrosoftAzure} size={2} /> },
    { name: 'GCP', platform: <Icon path={mdiGoogleCloud} size={2} /> },
    { name: 'Linux', platform: <FontAwesomeIcon icon={faLinux} /> }
];

const queryParamMap = {
    'all': 'all',
};

const colorMap = {};

const vectorPropNameMap = {};

vectors.forEach(vector => {
    queryParamMap[vector.path] = vector.title;
    colorMap[vector.title] = vector.hexColor;
    vectorPropNameMap[vector.title] = vector.propName;
});

const swordIcon = <i className='mdi mdi-sword' />;
const shieldIcon = <i className='mdi mdi-shield' />;
const platformIcon = <i className='mdi mdi-minecraft' />;
const edrsIcon = <i className='mdi mdi-buffer' />;

class BulletMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            selectedBulletId: -1,
            silverBullets: [],
            payloadFilter: '',
            defRuleFilter: '',
            searchFilter: '',
            platformFilter: '',
            permissionPopup: false,
            selectedDate: null,
            edrs: EDRs,
            edrFilter: '',
            filterInternetRequired: false,
            filterLocalAdminRequired: false,
            filterNotInternetRequired: false,
            filterNotLocalAdminRequired: false,
            edrTags: {},
            edrPropNames: [],
            edrNames: [],
            isExpanded: false,
            payloadModalOpen: false,
            DrModalOpen: false,
            selectedBulletForPayloadModal: null,
            selectedBulletForDrModal: null,
            expandedBullets: {},
            bulletHeights: {},
            selectedRunInCompany: '',
            selectedNotRunInCompany: '',
            companies: [],
            // New state variables for selection mode
            isSelectionMode: false,
            selectedBullets: [],
            dropdownsOpen: {}, // To manage dropdown open state for each bullet
            showDownloadOptions: false, // To toggle the visibility of download options
            downloadPayloads: false, // Checkbox state for payloads
            downloadDefRules: false, // Checkbox state for defense rules
        };

        this.setNewAction = this.setNewAction.bind(this);
        this.closeBullet = this.closeBullet.bind(this);
        this.registerAction = this.registerAction.bind(this);
        this.updatedAction = this.updatedAction.bind(this);
        this.switchFilter = this.switchFilter.bind(this);
        this.filterBySearch = this.filterBySearch.bind(this);
        this.getAllBulletsEdrs = this.getAllBulletsEdrs.bind(this);
        this.switchEdrFilter = this.switchEdrFilter.bind(this);
        this.handleToggleRelevance = this.handleToggleRelevance.bind(this);
        this.toggleExpand = this.toggleExpand.bind(this);
        this.needsExpansion = this.needsExpansion.bind(this);
        this.togglePayloadModal = this.togglePayloadModal.bind(this);
        this.toggleDrModal = this.toggleDrModal.bind(this);
        this.measureHeights = this.measureHeights.bind(this);
        this.setRef = this.setRef.bind(this);
        // New bindings for selection mode
        this.enterSelectionMode = this.enterSelectionMode.bind(this);
        this.exitSelectionMode = this.exitSelectionMode.bind(this);
        this.toggleBulletSelection = this.toggleBulletSelection.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleDownloadOptions = this.toggleDownloadOptions.bind(this);
        this.handleDownloadChange = this.handleDownloadChange.bind(this);
        this.confirmDownload = this.confirmDownload.bind(this);
        this.contentRefs = {};
    }

    setRef = (id) => (el) => {
        this.contentRefs[id] = el;
        if (el) {
            this.measureHeights(); // Measure heights when refs are assigned
        }
    };

    measureHeights() {
        const bulletHeights = {};
        Object.keys(this.contentRefs).forEach(id => {
            if (this.contentRefs[id]) {
                bulletHeights[id] = this.contentRefs[id].scrollHeight;
            }
        });

        // Only update state if the heights have changed
        const heightsChanged = Object.keys(bulletHeights).some(
            id => this.state.bulletHeights[id] !== bulletHeights[id]
        );

        if (heightsChanged) {
            this.setState({ bulletHeights });
        }
    }

    togglePayloadModal(bullet) {
        this.setState(prevState => ({ payloadModalOpen: !prevState.payloadModalOpen, selectedBulletForPayloadModal: bullet }));
    }

    toggleDrModal(bullet) {
        this.setState(prevState => ({ DrModalOpen: !prevState.DrModalOpen, selectedBulletForDrModal: bullet }));
    }

    needsExpansion = (bulletId) => {
        const element = document.getElementById(`bullet-content-${bulletId}`);
        return element && element.scrollHeight > 100; // Set threshold to 100px
    };

    toggleExpand = (bulletId) => {
        this.setState((prevState) => {
            const isExpanded = !prevState.expandedBullets[bulletId];
            return {
                expandedBullets: {
                    ...prevState.expandedBullets,
                    [bulletId]: isExpanded
                }
            };
        });
    };

    closeBullet() {
        this.setState({ selectedBulletId: -1 });
    }

    setNewAction(bulletId, action) {
        this.setState(state => {
            const newActions = {};
            Object.assign(newActions, state.actions);
            newActions[bulletId] = action;
            return { actions: newActions };
        });
    }

    switchEdrFilter = (edrId) => {
        if (this.state.edrFilter !== edrId) {
            this.setState({ edrFilter: edrId });
        } else {
            this.setState({ edrFilter: '' }); // Reset if the same ID is clicked again
        }
    }

    handleClosePermissionPopup = () => {
        this.setState({ permissionPopup: false });
    };

    registerAction(bulletId, action) {
        return sdk.actions.register(bulletId, action).then(action => {
            this.setNewAction(bulletId, action);
        });
    }

    updatedAction(bulletId) {
        sdk.actions.getLatestAction(bulletId).then(action => {
            this.setNewAction(bulletId, action);
        });
        sdk.actions.countDownloadedActions().then(res => {
            this.setState({ downloadedCount: res.count });
        });
    }

    switchFilter(filterName, value) {
        this.setState(state => {
            const newState = {};
            newState[filterName] = state[filterName] !== value ? value : '';
            return newState;
        });
    }

    async getAllBulletsEdrs() {
        const allBulletsEdrs = await sdk.bulletsEdrs.get();
        const bulletIdToEdrsMap = new Map();
        allBulletsEdrs.forEach(edrs => {
            if (!bulletIdToEdrsMap.has(edrs.bulletId)) {
                bulletIdToEdrsMap.set(edrs.bulletId, []);
            }
            bulletIdToEdrsMap.get(edrs.bulletId).push(edrs);
        });
        return bulletIdToEdrsMap;
    }

    handleDateChange = (event) => {
        this.setState({ selectedDate: event.target.value });
    }

    clearDate = () => {
        this.setState({ selectedDate: null });
    }

    filterBySearch(bullet) {
        if (!this.state.searchFilter && !this.state.edrFilter) return true;

        const searchQuery = this.state.searchFilter.toLowerCase();

        // Check if bullet matches search query
        const searchMatch = this.state.searchFilter ? (
            bullet.name.toLowerCase().includes(searchQuery) ||
            bullet.techniques.some(technique =>
                technique.name.toLowerCase().includes(searchQuery) ||
                technique.mitreId.toLowerCase().includes(searchQuery)
            ) ||
            bullet.aptTags.some(tag => tag.aptName.toLowerCase().includes(searchQuery))
        ) : true;

        // Update the EDR filter to check against defense rule's edrTagName using the edrTagMap
        const edrFilterSatisfied = !this.state.edrFilter || (bullet.defenceRules && bullet.defenceRules.some(defRule => {
            const edrTagName = this.state.edrTagMap[defRule.edrTagId]; // Access the name using the map
            return edrTagName && edrTagName.toLowerCase() === this.state.edrFilter.toLowerCase();
        }));

        return searchMatch && edrFilterSatisfied;
    }

    handleToggleRelevance = (bulletId, isIrrelevant) => {
        // Update local state
        const updatedBullets = this.state.silverBullets.map(bullet => {
            if (bullet.id === bulletId) {
                return { ...bullet, irrelevant: isIrrelevant };
            }
            return bullet;
        });

        this.setState({ silverBullets: updatedBullets }, () => {
            this.measureHeights(); // Ensure heights are measured after state update
        });

        // Call API to update database
        sdk.silverBullets.update(bulletId, { irrelevant: isIrrelevant })
            .then(() => {
                console.log('Bullet updated successfully');
                this.measureHeights(); // Ensure heights are measured again
            })
            .catch(error => {
                console.error('Error updating bullet:', error);
                // Handle error, possibly reverting the toggle switch to its original state
            });
    };

    loadEdrPropNames() {
        // Assuming EDRs is an array of objects where each object has a 'propName'
        const edrPropNames = EDRs.map(edr => edr.propName);
        const edrNames = EDRs.map(edr => edr.name);
        this.setState({ edrPropNames });
        this.setState({ edrNames });
    }

    componentDidMount() {
        this.loadEdrPropNames();
        sdk.edrTags.get().then(tags => {
            const edrTagMap = tags.reduce((acc, tag) => {
                acc[tag.id] = tag.edrName;
                return acc;
            }, {});
            this.setState({ edrTagMap });
        }).catch(error => {
            console.error('Failed to fetch EDR tags:', error);
        });

        const query = new URLSearchParams(this.props.location.search);
        let queryParamId = query.get('id');
        if (queryParamId) {
            this.props.history.push({ search: '' });
        }

        const techniqueId = this.props.match.params.techniqueId;

        const bulletPromise = sdk.silverBullets.get({ include: ['aptTags', 'payloads', 'techniques', "edrs", "platforms", "defenceRules", 'runHistory'] }).then(silverBullets => {
            let attackVector = this.props.match.params.attackVector;
            const allSilverBullets = silverBullets;
            allSilverBullets.sort((a, b) => a.attackVector.localeCompare(b.attackVector));
            if (attackVector && attackVector !== 'all') {
                attackVector = queryParamMap[attackVector];

                if (!techniqueId) {
                    silverBullets = allSilverBullets.filter(bullet => bullet.attackVector === attackVector);
                } else {
                    silverBullets = allSilverBullets.filter(bullet => bullet.techniques.find(t => t.id === techniqueId || t.subTechniqueOfId === techniqueId));
                }
            }

            return {
                silverBullets,
                allSilverBullets
            };
        });

        const actionPromise = sdk.actions.getLatestActionsBySilverBullet().then(actions => {
            const actionsByBulletId = {};

            actions.forEach(action => {
                actionsByBulletId[action.silverBulletId] = action;
            });

            return actionsByBulletId;
        });

        const licensePromise = sdk.licenses.getStatus(1);
        const countDownloadedPromise = sdk.actions.countDownloadedActions();
        const techniquePromise = techniqueId ? sdk.techniques.getById(techniqueId) : Promise.resolve(null);

        const edrTagsPromise = sdk.edrTags.get({})
            .then(tags => {
                const edrNameMap = tags.reduce((acc, tag) => {
                    acc[tag.id] = tag.edrName;
                    return acc;
                }, {});
                return edrNameMap;
            })
            .catch(error => {
                console.error('Failed to fetch EDR tags:', error);
                return {};
            });

        Promise.all([
            bulletPromise,
            actionPromise,
            licensePromise,
            countDownloadedPromise,
            techniquePromise,
            edrTagsPromise
        ]).then(res => {
            const newState = {
                loading: false
            };

            const bullets = res[0].silverBullets;

            let selectedBulletId = -1;
            if (queryParamId && !isNaN(queryParamId)) {
                queryParamId = parseInt(queryParamId);
                const bulletIds = bullets.map(b => b.id);
                if (bulletIds.includes(queryParamId)) {
                    selectedBulletId = queryParamId;
                }
            }

            Object.assign(newState,
                res[0],
                {
                    actions: res[1],
                    license: res[2],
                    downloadedCount: res[3].count,
                    selectedBulletId,
                    technique: res[4],
                    edrTags: res[5]
                });

            this.setState(newState, this.measureHeights);
        });

        sdk.companies.getAll().then(companies => {
            this.setState({ companies });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // Measure heights if relevant state has changed
        if (
            this.state.silverBullets !== prevState.silverBullets ||
            this.state.expandedBullets !== prevState.expandedBullets
        ) {
            this.measureHeights();
        }

        if (this.state.selectedBulletId >= 0 && prevState.selectedBulletId < 0) {
            // Scroll to the top of the page
            window.scrollTo(0, 0);
        }

        if (!this.state.allSilverBullets) return;
        let currentVector = this.props.match.params.attackVector;
        const techniqueId = this.props.match.params.techniqueId;
        if (techniqueId !== prevProps.match.params.techniqueId) {
            this.setState({ technique: null });
        }
        if (prevProps.match.params.attackVector !== currentVector || (this.state.allSilverBullets && !prevState.allSilverBullets)) {
            if (currentVector === 'all') {
                this.setState(state => ({
                    silverBullets: state.allSilverBullets,
                    selectedBulletId: -1
                }), this.measureHeights);
            } else {
                currentVector = queryParamMap[currentVector];
                this.setState(state => {
                    let silverBullets;
                    if (!techniqueId) {
                        silverBullets = state.allSilverBullets.filter(bullet => bullet.attackVector === currentVector);
                    } else {
                        silverBullets = state.allSilverBullets.filter(bullet => bullet.techniques.find(t => t.id === techniqueId || t.subTechniqueOfId === techniqueId));
                    }
                    return {
                        silverBullets,
                        selectedBulletId: -1
                    };
                }, this.measureHeights);
            }
        }
    }

    // New methods for selection mode

    enterSelectionMode(bulletId) {
        this.setState({
            isSelectionMode: true,
            selectedBullets: [bulletId]
        });
    }

    exitSelectionMode() {
        this.setState({
            isSelectionMode: false,
            selectedBullets: []
        });
    }

    toggleBulletSelection(bulletId) {
        this.setState((prevState) => {
            const { selectedBullets } = prevState;
            if (selectedBullets.includes(bulletId)) {
                // Deselect the bullet
                return { selectedBullets: selectedBullets.filter((id) => id !== bulletId) };
            } else {
                // Select the bullet
                return { selectedBullets: [...selectedBullets, bulletId] };
            }
        });
    }

    toggleDropdown(bulletId) {
        this.setState(prevState => ({
            dropdownsOpen: {
                ...prevState.dropdownsOpen,
                [bulletId]: !prevState.dropdownsOpen[bulletId]
            }
        }));
    }

    toggleDownloadOptions = () => {
        this.setState((prevState) => ({
            showDownloadOptions: !prevState.showDownloadOptions,
        }));
    };

    handleDownloadChange = (option) => {
        this.setState((prevState) => ({
            [option]: !prevState[option],
        }));
    };

    confirmDownload = () => {
        const { selectedBullets, downloadPayloads, downloadDefRules } = this.state;

        if (selectedBullets.length === 0) {
            alert('No bullets selected for download.');
            return;
        }

        selectedBullets.forEach((bulletId) => {
            if (downloadPayloads) {
                sdk.silverBullets.getPayloads(bulletId).then((payloads) => {
                    payloads.forEach((payload) =>
                        sdk.silverBullets
                            .downloadPayload(bulletId, payload.id, payload.fileName)
                            .catch((err) =>
                                console.error(`Error downloading payload:`, err)
                            )
                    );
                });
            }

            if (downloadDefRules) {
                sdk.silverBullets.getDefRules(bulletId).then((defRules) => {
                    defRules.forEach((defRule) =>
                        sdk.silverBullets
                            .downloadDefRule(bulletId, defRule.id, defRule.fileName)
                            .catch((err) =>
                                console.error(`Error downloading defense rule:`, err)
                            )
                    );
                });
            }
        });

        // Reset options after download
        this.setState({
            showDownloadOptions: false,
            downloadPayloads: false,
            downloadDefRules: false,
        });
    };



    render() {

        const { expandedBullets } = this.state;
        const selectedBullet = this.state.silverBullets.find(bullet => bullet.id === this.state.selectedBulletId)
        const colWidth = this.state.selectedBulletId >= 0 ? 6 : 12;
        let selectedCount = 0;
        let exceededQuota = false;
        if (this.state.actions && this.state.license && this.state.license.eyeOfTheEnemyLicense.licenseType === 'quota') {
            selectedCount = Object.values(this.state.actions).filter(action => (action.payloadStatus === 'selected' && action.defRuleStatus === 'selected')).length;
            exceededQuota = this.state.license.usedQuota + selectedCount >= this.state.license.eyeOfTheEnemyLicense.quota;
        }
        const currentBullets = this.state.silverBullets
            .filter(bullet => bullet.status !== 'offline')
            .filter(bullet => bullet.upload_completed !== false)
            .filter(this.filterBySearch)
            .filter(bullet => {
                let payloadStatus = '';
                let defRuleStatus = '';
                if (this.state.actions && this.state.actions[bullet.id]) {
                    const action = this.state.actions[bullet.id];
                    payloadStatus = action.payloadStatus !== 'discarded' ? action.payloadStatus : 'available';
                    defRuleStatus = action.defRuleStatus !== 'discarded' ? action.defRuleStatus : 'available';
                } else {
                    payloadStatus = 'available';
                    defRuleStatus = 'available';
                }

                let flatPlatforms = {};
                if (bullet.platforms) {
                    bullet.platforms.forEach(platform => {
                        for (let key in platform) {
                            if (platform[key] === true) {
                                flatPlatforms[key.toLowerCase()] = true;
                            }
                        }
                    });
                }

                const platformFilterSatisfied = !this.state.platformFilter || flatPlatforms[this.state.platformFilter];

                // Date filter logic
                let dateFilterSatisfied = true;
                if (this.state.selectedDate) {
                    const bulletDate = new Date(bullet.createdAt);
                    bulletDate.setHours(0, 0, 0, 0); // Set time to midnight

                    const selectedDate = new Date(this.state.selectedDate);
                    selectedDate.setHours(0, 0, 0, 0); // Set time to midnight

                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0); // Set time to midnight

                    dateFilterSatisfied = bulletDate >= selectedDate && bulletDate <= currentDate;
                }

                const runInCompanySatisfied = !this.state.selectedRunInCompany ||
                    bullet.runHistory.some(run => run.companyName === this.state.selectedRunInCompany);

                const notRunInCompanySatisfied = !this.state.selectedNotRunInCompany ||
                    !bullet.runHistory.some(run => run.companyName === this.state.selectedNotRunInCompany);

                return (
                    payloadStatus.startsWith(this.state.payloadFilter) &&
                    defRuleStatus.startsWith(this.state.defRuleFilter) &&
                    platformFilterSatisfied &&
                    dateFilterSatisfied &&
                    runInCompanySatisfied &&
                    notRunInCompanySatisfied &&
                    (!this.state.filterInternetRequired || bullet.needForInternet) &&
                    (!this.state.filterLocalAdminRequired || bullet.requireLocalAdmin) &&
                    (!this.state.filterNotInternetRequired || !bullet.needForInternet) &&
                    (!this.state.filterNotLocalAdminRequired || !bullet.requireLocalAdmin)
                );
            });

        return (
            <Row>
                <PermissionPopup isOpen={this.state.permissionPopup} onClose={this.handleClosePermissionPopup} />
                {this.state.selectedBulletId >= 0 ?
                    <Col lg='12' xl='6'>
                        <Bullet
                            bullet={selectedBullet}
                            action={this.state.actions[this.state.selectedBulletId]}
                            license={this.state.license ? this.state.license.eyeOfTheEnemyLicense : null}
                            downloadedCount={this.state.downloadedCount}
                            closeFunc={this.closeBullet}
                            registerFunc={this.registerAction}
                            updateFunc={this.updatedAction}
                            exceededQuota={exceededQuota}
                            onToggleRelevance={this.handleToggleRelevance}
                        />
                    </Col>
                    : null
                }
                <Col lg='12' xl={colWidth}>
                    <div className='filter-container'>
                        <div className='filters-row'>

                            <div className='filter-container mb-3'>
                                <h3 className='font-light pt-1 mr-1'>{swordIcon}</h3>
                                <ButtonGroup>
                                    <Button color='danger' active={this.state.payloadFilter === 'available'} onClick={() => this.switchFilter('payloadFilter', 'available')}>Available</Button>
                                    <Button color='danger' active={this.state.payloadFilter === 'selected'} onClick={() => this.switchFilter('payloadFilter', 'selected')}>Selected</Button>
                                    <Button color='danger' active={this.state.payloadFilter === 'downloaded'} onClick={() => this.switchFilter('payloadFilter', 'downloaded')}>Downloaded</Button>
                                    <Button color='danger' active={this.state.payloadFilter === 'executed'} onClick={() => this.switchFilter('payloadFilter', 'executed')}>Executed</Button>
                                    <Button color='danger' active={this.state.payloadFilter === 'passed'} onClick={() => this.switchFilter('payloadFilter', 'passed')}>Passed</Button>
                                    <Button color='danger' active={this.state.payloadFilter === 'blocked'} onClick={() => this.switchFilter('payloadFilter', 'blocked')}>Blocked</Button>
                                </ButtonGroup>
                            </div>
                            <div className='filter-container mb-3'>
                                <h3 className='font-light pt-1 mr-1'>{shieldIcon}</h3>
                                <ButtonGroup>
                                    <Button color='info' active={this.state.defRuleFilter === 'available'} onClick={() => this.switchFilter('defRuleFilter', 'available')}>Available</Button>
                                    <Button color='info' active={this.state.defRuleFilter === 'selected'} onClick={() => this.switchFilter('defRuleFilter', 'selected')}>Selected</Button>
                                    <Button color='info' active={this.state.defRuleFilter === 'downloaded'} onClick={() => this.switchFilter('defRuleFilter', 'downloaded')}>Downloaded</Button>
                                    <Button color='info' active={this.state.defRuleFilter === 'deployed'} onClick={() => this.switchFilter('defRuleFilter', 'deployed')}>Deployed</Button>
                                </ButtonGroup>
                            </div>
                            <div className='filter-container mb-3'>
                                <h3 className='font-light pt-1 mr-1'>{platformIcon}</h3> {/* Replace with your platform icon or string */}
                                <ButtonGroup>
                                    {Platforms.map(platform => (
                                        <Button
                                            key={platform.name}
                                            color='warning'
                                            style={{ color: 'white' }}
                                            active={this.state.platformFilter === platform.name.toLowerCase()}
                                            onClick={() => this.switchFilter('platformFilter', platform.name.toLowerCase())}
                                        >
                                            {platform.name}
                                        </Button>
                                    ))}
                                </ButtonGroup>
                            </div>
                            <div className='filter-container mb-3'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h3 className='font-light pt-1 mr-1'>{edrsIcon}</h3>
                                    <div className='filter-container' style={{ marginLeft: '-1px' }}>
                                        {this.state.edrs.map(edr => (
                                            <Button
                                                key={edr.propName}
                                                color='success'
                                                active={this.state.edrFilter === edr.propName}
                                                onClick={() => this.switchEdrFilter(edr.propName)}
                                                style={{ marginBottom: '10px', margin: '1px' }}
                                            >
                                                {edr.name}
                                            </Button>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='filter-container mb-3'>
                                <h3 className='font-light pt-1 mr-1'>Date:</h3>
                                <input type="date" value={this.state.selectedDate || ''} onChange={this.handleDateChange} />
                                <button className="btn btn-secondary ml-2" onClick={this.clearDate}>Clear Date</button>
                            </div>
                        </div>
                    </div>
                    <Col xs="auto">
                        <Switch
                            checked={this.state.filterInternetRequired}
                            onChange={(e) => this.setState({ filterInternetRequired: e.target.checked })}
                        />
                        <Label>Requires Internet</Label>
                        <Switch
                            checked={this.state.filterNotInternetRequired}
                            onChange={(e) => this.setState({ filterNotInternetRequired: e.target.checked })}
                        />
                        <Label>Not Requires Internet</Label>
                    </Col>
                    <Col xs="auto">
                        <Switch
                            checked={this.state.filterLocalAdminRequired}
                            onChange={(e) => this.setState({ filterLocalAdminRequired: e.target.checked })}
                        />
                        <Label>Requires Local Admin</Label>
                        <Switch
                            checked={this.state.filterNotLocalAdminRequired}
                            onChange={(e) => this.setState({ filterNotLocalAdminRequired: e.target.checked })}
                        />
                        <Label>Not Requires Local Admin</Label>
                    </Col>
                    <Col>
                        <Row>
                            <Col md="5">
                                <div className='filter-container mb-3'>
                                    <Label for="runInCompany">Run In</Label>
                                    <select id="runInCompany" className="form-control" value={this.state.selectedRunInCompany} onChange={e => this.setState({ selectedRunInCompany: e.target.value })}>
                                        <option value=''>All</option>
                                        {this.state.companies.map(company => (
                                            <option key={company.companyID} value={company.companyName}>{company.companyName}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                            <Col md="5">
                                <div className='filter-container mb-3'>
                                    <Label for="notRunInCompany">Not Run In</Label>
                                    <select id="notRunInCompany" className="form-control" value={this.state.selectedNotRunInCompany} onChange={e => this.setState({ selectedNotRunInCompany: e.target.value })}>
                                        <option value=''>All</option>
                                        {this.state.companies.map(company => (
                                            <option key={company.companyID} value={company.companyName}>{company.companyName}</option>
                                        ))}
                                    </select>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col className='filter-container search-container mb-3'>
                        <SearchBar onChange={e => this.switchFilter('searchFilter', e.target.value)} isLoading={this.state.loading} />
                    </Col>

                    {/* Options bar for selection mode */}
                    {this.state.isSelectionMode && (
                        <div
                            className="options-bar"
                            style={{
                                marginBottom: '20px',
                                marginLeft: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                                padding: '10px',
                                background: '#f8f9fa',
                                borderRadius: '10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            {/* Main action buttons */}
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <button
                                    className="btn btn-primary"
                                    style={{
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        borderRadius: '5px',
                                    }}
                                    onClick={this.toggleDownloadOptions}
                                >
                                    Download Data
                                </button>
                                <button
                                    className="btn btn-secondary"
                                    style={{
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        borderRadius: '5px',
                                    }}
                                    onClick={this.exitSelectionMode}
                                >
                                    Cancel
                                </button>
                            </div>

                            {/* Download options */}
                            {this.state.showDownloadOptions && (
                                <div
                                    style={{
                                        marginTop: '10px',
                                        padding: '15px',
                                        border: '1px solid #ccc',
                                        borderRadius: '8px',
                                        background: '#ffffff',
                                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <h5
                                        style={{
                                            marginBottom: '10px',
                                            fontWeight: 'bold',
                                            color: '#333',
                                        }}
                                    >
                                        Download Options
                                    </h5>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                        }}
                                    >
                                        <label
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={this.state.downloadPayloads}
                                                onChange={() => this.handleDownloadChange('downloadPayloads')}
                                                style={{
                                                    width: '18px',
                                                    height: '18px',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                            <span>Payloads</span>
                                        </label>
                                        <label
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '10px',
                                                fontSize: '14px',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={this.state.downloadDefRules}
                                                onChange={() => this.handleDownloadChange('downloadDefRules')}
                                                style={{
                                                    width: '18px',
                                                    height: '18px',
                                                    cursor: 'pointer',
                                                }}
                                            />
                                            <span>Defense Rules</span>
                                        </label>
                                        <button
                                            className="btn btn-primary"
                                            style={{
                                                padding: '10px 20px',
                                                fontSize: '14px',
                                                marginTop: '10px',
                                                borderRadius: '5px',
                                            }}
                                            onClick={this.confirmDownload}
                                        >
                                            Confirm Download
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}




                    {this.state.loading ? <div className='text-center'><i className='fas fa-spin fa-spinner big-profile-icon' /></div> : null}
                    {this.state.technique ?
                        <div className='ml-3'>
                            <h3>Technique: {this.state.technique.name}</h3>
                            <h3 className="ml-1">
                                <a target="_blank" rel="noreferrer noopener" href={`https://attack.mitre.org/techniques/${this.state.technique.mitreId}`}>
                                    {this.state.technique.mitreId} <small><sup><i className="fas fa-external-link-alt" /></sup></small>
                                </a>
                            </h3>
                            <p className='ml-2'>{this.state.technique.description}</p>
                        </div>
                        : null}
                    {!this.state.loading && currentBullets.length === 0 ? <h1 className='text-center'>No Silver Bullets</h1> : null}

                    <div className={`bullets-container ${this.state.selectedBulletId >= 0 ? 'narrow-menu' : 'wide-menu'}`}>

                        {currentBullets.map((bullet) => {
                            let payloadStatus = '';
                            let defRuleStatus = '';
                            if (this.state.actions && this.state.actions[bullet.id]) {
                                const action = this.state.actions[bullet.id];
                                payloadStatus = action.payloadStatus !== 'discarded' ? action.payloadStatus : 'available';
                                defRuleStatus = action.defRuleStatus !== 'discarded' ? action.defRuleStatus : 'available';
                            } else {
                                payloadStatus = 'available';
                                defRuleStatus = 'available';
                            }
                            const platformMatches = bullet.platforms && bullet.platforms.some(platform => platform[this.state.platformFilter]);

                            const filteredOut = !(payloadStatus.startsWith(this.state.payloadFilter) && defRuleStatus.startsWith(this.state.defRuleFilter)) && platformMatches;

                            let lockedOut = false;
                            if (this.state.license && this.state.license.eyeOfTheEnemyLicense.licenseType === 'quota') {
                                if (exceededQuota) {
                                    lockedOut = payloadStatus === 'available' && defRuleStatus === 'available';
                                }
                            } else if (this.state.license && this.state.license.eyeOfTheEnemyLicense.licenseType === 'attack vectors') {
                                lockedOut = !this.state.license.eyeOfTheEnemyLicense[vectorPropNameMap[bullet.attackVector]]
                            }

                            const currentDate = new Date();
                            const createdAt = new Date(bullet.createdAt);
                            const isNew = currentDate - createdAt < 14 * 24 * 60 * 60 * 1000;
                            const showTag = bullet.irrelevant || bullet.needForInternet || bullet.requireLocalAdmin;
                            let bulletClassName = 'silver-bullet';
                            bulletClassName += filteredOut ? ' filtered-out' : '';
                            bulletClassName += lockedOut ? ' locked-out ' : '';
                            bulletClassName += isNew ? ' new-bullet ' : '';
                            let flatPlatforms = {};
                            if (bullet.platforms) {
                                bullet.platforms.forEach(platform => {
                                    for (let key in platform) {
                                        if (platform[key] === true) {
                                            flatPlatforms[key.toLowerCase()] = true;
                                        }
                                    }
                                });
                            }

                            const isSelected = this.state.selectedBullets.includes(bullet.id);

                            return (
                                <Col lg='12' xl='6' key={bullet.id}>
                                    <Card
                                        inverse
                                        title={bullet.name}
                                        key={`bullet-card-${bullet.id}`}
                                        style={{ boxShadow: '2px 3px 1px rgba(0,0,0,0.2)', backgroundColor: "#424953", marginBottom: '20px', minHeight: this.state.selectedBulletId >= 0 ? '300px' : '270px', position: 'relative' }}
                                        className={`bulletCard ${bulletClassName}`}
                                        onClick={(event) => {
                                            if (this.state.isSelectionMode) {
                                                event.stopPropagation();
                                                this.toggleBulletSelection(bullet.id);
                                            } else {
                                                if (filteredOut) {
                                                    return;
                                                }
                                                this.setState({ selectedBulletId: this.state.selectedBulletId !== bullet.id ? bullet.id : -1 });
                                                window.scrollTo(0, 0);
                                            }
                                        }}
                                    >
                                        {/* Checkbox for selection mode (bigger and top-right) */}
                                        {this.state.isSelectionMode && (
                                            <div
                                                className="checkbox-container"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    this.toggleBulletSelection(bullet.id);
                                                }}
                                                style={{ position: 'absolute', top: '10px', right: '10px' }}
                                            >
                                                <input type="checkbox" checked={isSelected} readOnly style={{ width: '24px', height: '24px' }} />
                                            </div>
                                        )}

                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div style={{ flexGrow: 1 }}>
                                                    <CardTitle tag='h2' style={{ marginLeft: '13px', marginTop: '7px' }}>
                                                        {lockedOut ? <><i className='mdi mdi-lock lock-icon' />{' '}</> : null}
                                                        {bullet.name}
                                                        {isNew ? <span className='new-tag'><h4>New</h4></span> : null}
                                                    </CardTitle>
                                                    <CardSubtitle tag='h4' style={{ marginLeft: '13px' }}>
                                                        <div className="circle" style={{ backgroundColor: bullet.attackVector ? colorMap[bullet.attackVector] : 'secondary' }}></div>
                                                        {bullet.attackVector}
                                                    </CardSubtitle>
                                                </div>
                                                {/* Flex container for platform images aligned to the right */}
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', marginRight: '13px' }}>
                                                    {Platforms.map((platformObj) => {
                                                        let platformName = platformObj.name.toLowerCase();
                                                        if (flatPlatforms[platformName]) {
                                                            return (
                                                                <div
                                                                    key={platformName}
                                                                    style={{ marginLeft: 8, fontSize: '40px', lineHeight: '40px' }} // Set size and alignment
                                                                >
                                                                    {platformObj.platform}
                                                                </div>
                                                            )
                                                        }
                                                        return null;
                                                    })}
                                                </div>
                                            </div>
                                            <div className="line"></div>
                                            {showTag && <div style={{ marginBottom: '10px', marginLeft: '13px' }}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', alignItems: 'center' }}>
                                                    {bullet.irrelevant && (
                                                        <div className="irrelevant-circle">
                                                            <span>Irrelevant</span>
                                                        </div>
                                                    )}
                                                    {bullet.needForInternet && (
                                                        <div className="tag-circle">
                                                            <i className='mdi mdi-wifi' style={{ color: 'blue', marginRight: '4px' }} /> Internet
                                                        </div>
                                                    )}
                                                    {bullet.requireLocalAdmin && (
                                                        <div className="tag-circle">
                                                            <i className='mdi mdi-account-key' style={{ color: 'red', marginRight: '4px' }} /> Local Admin
                                                        </div>
                                                    )}
                                                </div>
                                            </div>}
                                            <div style={{ marginLeft: '13px' }}>
                                                <div
                                                    id={`bullet-content-${bullet.id}`}
                                                    ref={this.setRef(bullet.id)}
                                                    className={this.state.bulletHeights[bullet.id] > (showTag ? 100 : 130) && !expandedBullets[bullet.id] ? 'fade-out' : ''}
                                                    style={{
                                                        overflow: 'hidden',
                                                        height: expandedBullets[bullet.id] ? `${this.state.bulletHeights[bullet.id]}px` : showTag ? '80px' : '110px',
                                                        transition: 'height 0.5s ease',
                                                        marginRight: '1px'
                                                    }}
                                                >
                                                    {bullet.description}
                                                </div>
                                                {this.state.bulletHeights[bullet.id] > (showTag ? 100 : 130) && (
                                                    <div
                                                        onClick={(event) => {
                                                            event.stopPropagation(); // This stops the event from bubbling up to the card's onClick
                                                            this.toggleExpand(bullet.id);
                                                        }}
                                                        style={{ textAlign: 'center', cursor: 'pointer' }}
                                                    >
                                                        <i className={`change-on-hover fas fa-chevron-${expandedBullets[bullet.id] ? 'up' : 'down'}`} style={{ fontSize: '24px' }}></i>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="buttonsContainer">
                                            <div className="linelight"></div>
                                            <div style={{ display: 'flex', alignItems: 'center', padding: '0 13px' }}>
                                                <span
                                                    className="change-on-hover"
                                                    style={{ fontSize: '18px', marginRight: '19px' }}
                                                    onClick={(event) => { event.stopPropagation(); this.togglePayloadModal(bullet); }}
                                                >
                                                    Download Payload
                                                </span>
                                                <span
                                                    className="change-on-hover"
                                                    style={{ fontSize: '18px' }}
                                                    onClick={(event) => { event.stopPropagation(); this.toggleDrModal(bullet); }}
                                                >
                                                    Download Defense Rules
                                                </span>
                                            </div>
                                            {/* Dropdown menu for the three dots icon */}
                                            {!this.state.isSelectionMode && this.state.selectedBulletId < 0 && (
                                                <Dropdown
                                                    isOpen={this.state.dropdownsOpen[bullet.id]}
                                                    toggle={() => this.toggleDropdown(bullet.id)}
                                                    style={{ position: 'absolute', bottom: '4px', right: '10px' }} // Ensure absolute positioning
                                                >
                                                    <DropdownToggle
                                                        tag="span"
                                                        data-toggle="dropdown"
                                                        onClick={(e) => { e.stopPropagation(); }}
                                                    >
                                                        <i
                                                            className="fas fa-ellipsis-h" // Use horizontal dots icon
                                                            style={{ fontSize: '24px', cursor: 'pointer' }}
                                                        ></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu right>
                                                        <DropdownItem onClick={(e) => { e.stopPropagation(); this.enterSelectionMode(bullet.id); }}>
                                                            Select Card
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            )}
                                        </div>
                                    </Card>
                                </Col>
                            )
                        })}
                    </div>
                </Col>
                <PayloadModal
                    toggle={this.togglePayloadModal}
                    bullet={this.state.selectedBulletForPayloadModal}
                    isOpen={this.state.payloadModalOpen}
                    updateFunc={this.updatedAction}
                    fromDashBoard={true}
                />
                <DefRuleModal
                    toggle={this.toggleDrModal}
                    bullet={this.state.selectedBulletForDrModal}
                    isOpen={this.state.DrModalOpen}
                    updateFunc={this.updatedAction}
                />
            </Row >
        );
    }
}

export default BulletMenu;
