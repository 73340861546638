import Dashboard from '../views/dashboard';
import SilverBullets from '../views/silverBullets';
import UserManagement from '../views/userManagement';
import OrganizationManagement from '../views/organizationManagement';
import SilverBulletManagement from '../views/silverBulletManagement';
import SystemConfiguration from '../views/systemConfiguration';
import OrganizationConfiguration from '../views/organizationConfiguration';
import AccountSettings from '../views/accountSettings';
import TechniqueTable from '../views/techniqueTable';
import TechniqueManagement from '../views/techniqueManagement';
import DownloadPayloads from '../components/downloadPayloads/downloadPayloads';
import UserPayloadDownload from '../views/UserPayloadDownload';
import CompaniesTable from '../views/companiesTable';
import ReportForge from '../views/reportForge';
import attackVectors from '../assets/data/attackVectors.json';
import ScenarioCreation from '../views/scenarioCreation';
import AllScenarios from '../views/AllScenarios/AllScenarios';

const VectorRoutes = attackVectors.map(vector => ({
  path: `/silver-bullets/${vector.path}`,
  name: vector.title,
  hide: vector.hide,
  hexColor: vector.hexColor, 
  component: SilverBullets
}));
 
const SilverBulletsSidebarRoute = {
  name: 'Vectors',
  icon: 'mr-2 mdi mdi-arrow-right-thick',
  collapse: true,
  state: 'silverBulletsOpen',
  sidebar: true, // Indicate this route is only for the sidebar
  child: [
    ...VectorRoutes // Your existing attack vector routes
  ]
};

var ThemeRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'mr-2 mdi mdi-gauge',
    component: Dashboard
  },
  {
    path: '/technique-table',
    name: 'Technique Table',
    icon: 'mr-2 mdi mdi-view-grid',
    component: TechniqueTable
  },
  {
    path: '/account',
    name: 'Account Settings',
    hide:true,
    component: AccountSettings
  },
  {
    path: '/silver-bullets',
    name: 'Silver Bullets',
    hide:true,
    component: SilverBullets
  },
  {
    path: '/silver-bullets/all',
    name: 'All Attack Vectors',
    component: SilverBullets
  },
  SilverBulletsSidebarRoute,
  {
    path: '/companies-table',
    name: 'Companies Table',
    icon: 'mr-2 mdi mdi-city',
    rootOnly: true,
    uploaderOnly: true,
    component: CompaniesTable
  },
  {
    path: '/report-forge',
    name: 'Report Forge',
    icon: 'mr-2 mdi mdi-auto-fix',
    rootOnly: true,
    uploaderOnly: true,
    component: ReportForge
  },
  {
    path: '/all-scenarios',
    name: 'All Scenarios',
    icon: 'mr-2 mdi mdi-file-document',
    rootOnly: true,
    uploaderOnly: true,
    component: AllScenarios
  },
  {
    path: '/scenario-creation',
    name: 'Scenario Creation',
    icon: 'mr-2 mdi mdi-creation',
    rootOnly: true,
    uploaderOnly: true,
    component: ScenarioCreation
  },
  {
    path: '/user-payload-download',
    name: 'User payload downloads',
    icon: 'mr-2 mdi mdi-download',
    rootOnly: true,
    uploaderOnly: true,
    component: UserPayloadDownload
  },
  {
    path: '/silver-bullet-management',
    name: 'Silver Bullet management',
    icon: 'mr-2 mdi mdi-library-books',
    rootOnly: true,
    uploaderOnly: true,
    component: SilverBulletManagement
  },
  {
    path: '/technique-management',
    name: 'Technique management',
    icon: 'mr-2 mdi mdi-lightbulb',
    rootOnly: true,
    uploaderOnly: true,
    component: TechniqueManagement
  },
  {
    path: '/user-management',
    name: 'User management',
    icon: 'mr-2 mdi mdi-account-multiple',
    adminOnly: true,
    rootOnly: true,
    supportOnly: true,
    component: UserManagement
  },
  {
    path: '/organization-management',
    name: 'Organization management',
    icon: 'mr-2 mdi mdi-home-modern',
    rootOnly: true,
    component: OrganizationManagement
  },
  {
    path: '/download-payloads',
    name: 'Downlod Payloads',
    icon: 'mr-2 mdi mdi-folder-download',
    rootOnly: true,
    uploaderOnly: true,
    component: DownloadPayloads
  },
  {
    path: '/system-configuration',
    name: 'System Configuration',
    icon: 'mr-2 mdi mdi-settings',
    rootOnly: true,
    component: SystemConfiguration
  },
  {
    path: '/organization-configuration',
    name: 'Organization Configuration',
    icon: 'mr-2 mdi mdi-settings',
    adminOnly: true,
    component: OrganizationConfiguration
  },
  {
    path: '/',
    pathTo: '/dashboard',
    redirect: true
  },
];
export default ThemeRoutes;
