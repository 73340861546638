import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Collapse } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import user from '../../services/user';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.expandLogo = this.expandLogo.bind(this);
    this.activeRoute.bind(this);

    // Initialize collapse states for collapsible menus
    this.state = {
      dropdownOpen: false,
      silverBulletsOpen: false, // Add this line
      // Add other collapse states if you have more collapsible menus
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  /*--------------------------------------------------------------------------------*/
  /* To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  expandLogo() {
    document.getElementById('logobg').classList.toggle('expand-logo');
  }

  /*--------------------------------------------------------------------------------*/
  /* Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : '';
  }

  /*--------------------------------------------------------------------------------*/
  /* Helper method to check user permissions                                         */
  /*--------------------------------------------------------------------------------*/
  checkPermissions(prop) {
    const userRoles = user.getRoles();
    const isAdmin = userRoles.some(s => s === 'org-admin');
    const isSupport = userRoles.some(s => s === 'support');
    const isUploader = userRoles.some(s => s === 'uploader');
    const isRoot = user.isRoot();

    return (
      (!prop.adminOnly &&
        !prop.rootOnly &&
        !prop.supportOnly &&
        !prop.uploaderOnly &&
        !prop.hide) ||
      (prop.adminOnly && isAdmin) ||
      (prop.rootOnly && isRoot) ||
      (prop.supportOnly && isSupport) ||
      (prop.uploaderOnly && isUploader)
    );
  }

  render() {
    return (
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg={this.props.data.settings[0].sidebarbg}
        onMouseEnter={this.expandLogo}
        onMouseLeave={this.expandLogo}
      >
        <div className="scroll-sidebar">
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                     */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav">
              {this.props.routes.map((prop, key) => {
                if (prop.redirect) {
                  return null;
                } else if (prop.navlabel && this.checkPermissions(prop)) {
                  return (
                    <li className="nav-small-cap" key={key}>
                      <i className={prop.icon} />
                      <span className="hide-menu">{prop.name}</span>
                    </li>
                  );
                } else if (prop.collapse && this.checkPermissions(prop)) {
                  let firstdd = {};
                  firstdd[prop.state] = !this.state[prop.state];
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Collapsible Menu Item                                                           */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      className={this.activeRoute(prop.path) + ' sidebar-item'}
                      key={key}
                    >
                      <NavLink
                        to="#"
                        className="sidebar-link"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState(firstdd);
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <i className={prop.icon} />
                          <span className="hide-menu" >
                            {prop.name}
                          </span>
                        </div>
                        {this.state[prop.state] ? (
                          <FaChevronUp style={{ marginLeft: '5px' }} />
                        ) : (
                          <FaChevronDown style={{ marginLeft: '5px' }} />
                        )}
                      </NavLink>
                      {/*--------------------------------------------------------------------------------*/}
                      {/* Sub-Menus will go here                                                        */}
                      {/*--------------------------------------------------------------------------------*/}
                      <Collapse isOpen={this.state[prop.state]}>
                        <ul className="first-level">
                          {prop.child.map((childProp, childKey) => {
                            if (
                              childProp.redirect ||
                              childProp.hide ||
                              !this.checkPermissions(childProp)
                            )
                              return null;

                            return (
                              /*--------------------------------------------------------------------------------*/
                              /* Adding Sidebar Item for Child Routes                                          */
                              /*--------------------------------------------------------------------------------*/
                              <li
                                className={
                                  this.activeRoute(childProp.path) +
                                  (childProp.pro ? ' active active-pro' : '') +
                                  ' sidebar-item'
                                }
                                key={childKey}
                              >
                                <NavLink
                                  to={childProp.path}
                                  className="sidebar-link"
                                  activeClassName="active"
                                >
                                  <i className={childProp.icon} />
                                  <span
                                    className="hide-menu"
                                    style={{
                                      borderLeft: `5px solid ${childProp.hexColor
                                        ? childProp.hexColor
                                        : 'defaultBorderColor'
                                        }`,
                                      paddingLeft: '10px',
                                    }}
                                  >
                                    {childProp.name}
                                  </span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  );
                } else if (this.checkPermissions(prop)) {
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Adding Sidebar Item                                                            */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      className={
                        this.activeRoute(prop.path) +
                        (prop.pro ? ' active active-pro' : '') +
                        ' sidebar-item'
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop.path}
                        className="sidebar-link"
                        activeClassName="active"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <i className={prop.icon} />
                        <span
                          className="hide-menu"
                          style={{
                            borderLeft: `5px solid ${prop.hexColor ? prop.hexColor : 'defaultBorderColor'
                              }`,
                            paddingLeft: '10px',
                          }}
                        >
                          {prop.name}
                        </span>
                      </NavLink>
                    </li>
                  );
                }
                return null;
              })}
            </Nav>
          </PerfectScrollbar>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
