import { request, requestDownload } from './client';
var model = 'eye-of-the-enemy/DefenceRules';
var defRules = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  create: function create(payload) {
    return request('post', "/".concat(model), payload);
  },
  update: function update(id, rule) {
    return request('patch', "/".concat(model, "/").concat(id), rule);
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  },
  download: function download(id) {
    return requestDownload("/".concat(model, "/").concat(id, "/download"));
  },
  uploadFile: function uploadFile(file, id) {
    // eslint-disable-next-line no-undef
    var formData = new FormData();
    formData.append('file', file);
    var config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    return request('post', "/".concat(model, "/").concat(id, "/upload"), formData, config);
  },
  getDefRuleText: function getDefRuleText(id) {
    return request('get', "/".concat(model, "/").concat(id, "/get-text"));
  },
  getParagraphByTitle: function getParagraphByTitle(id, title) {
    return request('get', "/".concat(model, "/").concat(id, "/get-paragraph"), null, {
      title: title
    });
  }
};
export default defRules;